.bigfoot-footnote__button {
  position: relative;
  z-index: 5;
  top: -0.2em;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  display: inline-block;
  padding: 0.35em;
  margin: 0 0.1em 0 0.1em;
  border: none;
  border-radius: 0.3em;
  cursor: pointer;
  background-color: rgba(110, 110, 110, 0.2);
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  font-size: 1rem;
  line-height: 0;
  vertical-align: super;
  text-decoration: none;
  font-smoothing: antialiased;
  -webkit-transition-property: background-color;
  transition-property: background-color;
  -webkit-transition-duration: 0.25s;
  transition-duration: 0.25s;
}

.bigfoot-footnote__button:hover,
.bigfoot-footnote__button:focus {
  outline: none;
  background-color: rgba(110, 110, 110, 0.5);
}

.bigfoot-footnote__button:active {
  background-color: rgba(110, 110, 110, 0.5);
}

.bigfoot-footnote__button.is-active {
  background-color: #6e6e6e;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.bigfoot-footnote__button:after {
  content: '';
  display: table;
  clear: both;
}

.bigfoot-footnote__button__circle {
  display: inline-block;
  width: 0.25em;
  height: 0.25em;
  margin-right: 0.25em;
  float: left;
}

.bigfoot-footnote__button__circle:last-child {
  margin-right: 0;
}

.bigfoot-footnote__container {
  display: inline-block;
  position: relative;
  text-indent: 0;
}

@media not print {
  .footnote-print-only {
    display: none !important;
  }
}

@media print {
  .bigfoot-footnote,
  .bigfoot-footnote__button {
    display: none !important;
  }
}

.bigfoot-footnote {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  display: inline-block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 90%;
  margin: 1.96924em 0;
  background: #fafafa;
  opacity: 0;
  border-radius: 0.5em;
  border: 1px solid #c3c3c3;
  -webkit-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  line-height: 0;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  -webkit-transition-duration: 0.25s;
  transition-duration: 0.25s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transform: scale(0.1) translateZ(0);
  -ms-transform: scale(0.1) translateZ(0);
  transform: scale(0.1) translateZ(0);
  -webkit-transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  transform-origin: 50% 0;
}

.bigfoot-footnote.is-positioned-top {
  top: auto;
  bottom: 0;
}

.bigfoot-footnote.is-active {
  -webkit-transform: scale(1) translateZ(0);
  -ms-transform: scale(1) translateZ(0);
  transform: scale(1) translateZ(0);
  opacity: 0.97;
}

.bigfoot-footnote.is-bottom-fixed {
  position: fixed;
  bottom: 0;
  top: auto;
  left: 0;
  right: auto;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  width: 100%;
  margin: 0;
  border-radius: 0;
  opacity: 1;
  border-width: 1px 0 0;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
}

.bigfoot-footnote.is-bottom-fixed.is-active {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.bigfoot-footnote.is-bottom-fixed .bigfoot-footnote__wrapper {
  margin: 0 0 0 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  max-width: 100%;
}

.bigfoot-footnote.is-bottom-fixed .bigfoot-footnote__wrapper,
.bigfoot-footnote.is-bottom-fixed .bigfoot-footnote__content {
  border-radius: 0;
}

.bigfoot-footnote.is-bottom-fixed .bigfoot-footnote__tooltip {
  display: none;
}

.bigfoot-footnote.is-scrollable:after {
  content: '';
  position: absolute;
  bottom: 0.3375em;
  left: 0.3375em;
  z-index: 14;
  display: block;
  height: 0.78125em;
  width: 0.625em;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTJweCIgaGVpZ2h0PSIxNXB4IiB2aWV3Qm94PSIwIDAgMTIgMTUiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1pbllNaW4iPgogICAgPGcgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IkFycm93IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxLjAwMDAwMCwgMS4wMDAwMDApIiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiPgogICAgICAgICAgICA8cGF0aCBkPSJNNSwwIEw1LDExLjUiIGlkPSJMaW5lIj48L3BhdGg+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLjUsNy41IEw1LjAyNzY5Mjc5LDEyLjAyNzY5MjgiIGlkPSJMaW5lIj48L3BhdGg+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik00LjUsNy41IEw5LjAyNzY5Mjc5LDEyLjAyNzY5MjgiIGlkPSJMaW5lLTIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDcuMDAwMDAwLCAxMC4wMDAwMDApIHNjYWxlKC0xLCAxKSB0cmFuc2xhdGUoLTcuMDAwMDAwLCAtMTAuMDAwMDAwKSAiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=");
  -webkit-background-size: cover;
  background-size: cover;
  opacity: 0.1;
  transition-properties: opacity;
  -webkit-transition-duration: 0.25s;
  transition-duration: 0.25s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}

.bigfoot-footnote.is-scrollable .bigfoot-footnote__wrapper:before,
.bigfoot-footnote.is-scrollable .bigfoot-footnote__wrapper:after {
  content: '';
  position: absolute;
  width: 100%;
  z-index: 12;
  left: 0;
}

.bigfoot-footnote.is-scrollable .bigfoot-footnote__wrapper:before {
  top: -1px;
  height: 1.1em;
  border-radius: 0.5em 0.5em 0 0;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fafafa), to(rgba(250, 250, 250, 0)));
  background-image: -webkit-linear-gradient(top, #fafafa 50%, rgba(250, 250, 250, 0) 100%);
  background-image: linear-gradient(to bottom, #fafafa 50%, rgba(250, 250, 250, 0) 100%);
}

.bigfoot-footnote.is-scrollable .bigfoot-footnote__wrapper:after {
  bottom: -1px;
  height: 1.2em;
  border-radius: 0 0 0.5em 0.5em;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#fafafa), to(rgba(250, 250, 250, 0)));
  background-image: -webkit-linear-gradient(bottom, #fafafa 50%, rgba(250, 250, 250, 0) 100%);
  background-image: linear-gradient(to top, #fafafa 50%, rgba(250, 250, 250, 0) 100%);
}

.bigfoot-footnote.is-scrollable ::-webkit-scrollbar {
  display: none;
}

.bigfoot-footnote.is-fully-scrolled:after,
.bigfoot-footnote.is-fully-scrolled:before {
  opacity: 0;
  -webkit-transition-delay: 0;
  transition-delay: 0;
}

.bigfoot-footnote__wrapper {
  position: relative;
  z-index: 14;
  width: 22em;
  display: inline-block;
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
  overflow: hidden;
  margin: 0;
  background-color: #fafafa;
  border-radius: 0.5em;
  line-height: 0;
}

.bigfoot-footnote__content {
  position: relative;
  z-index: 8;
  display: inline-block;
  max-height: 15em;
  padding: 1.1em 1.3em 1.2em;
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background: #fafafa;
  border-radius: 0.5em;
  font-smoothing: subpixel-antialiased;
  line-height: normal;
}

.bigfoot-footnote__content img {
  max-width: 100%;
}

.bigfoot-footnote__content *:last-child {
  margin-bottom: 0 !important;
}

.bigfoot-footnote__content *:first-child {
  margin-top: 0 !important;
}

.bigfoot-footnote__tooltip {
  position: absolute;
  z-index: 12;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-left: -0.65em;
  width: 1.3em;
  height: 1.3em;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  background: #fafafa;
  border: 1px solid #c3c3c3;
  -webkit-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-top-left-radius: 0;
}

.is-positioned-bottom .bigfoot-footnote__tooltip {
  top: -0.65em;
}

.is-positioned-top .bigfoot-footnote__tooltip {
  bottom: -0.65em;
}

.bigfoot-footnote__button {
  position: relative;
  height: 0.0em;
  width: 0.0em;
  border-radius: 0.0em;
}

.bigfoot-footnote__button:after {
  content: attr(data-footnote-number);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: block;
  font-size: 0.57em;
  font-weight: bold;
  color: rgba(110, 110, 110, 0.5);
  -webkit-transition: color 0.25s ease;
  transition: color 0.25s ease;
}

.bigfoot-footnote__button:hover:after,
.bigfoot-footnote__button.is-active:after {
  color: white;
}

.bigfoot-footnote__button__circle {
  display: none;
}
