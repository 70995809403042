// Layout
//
// Styles for managing the structural hierarchy of the site.

.container {
  max-width: 38em;
  padding-left:  .75rem;
  padding-right: .75rem;
  margin-left:  auto;
  margin-right: auto;
}

footer {
  margin-bottom: 2rem;
}

hr {
            position: relative; 
            border: none; 
            height: 1px; 
            background: #8c8b8b 
}